
import HTTPService from './HTTPServices';
const ApiDomain = process.env.REACT_APP_API_DOMAIN;

const Api = {};
Api.requestHomePage = function () {
    return HTTPService.sendPostReqeust(ApiDomain + '/result')
};

Api.requestPageResult = function (dayOfWeek = '') {
    const params = {
        day_of_week: dayOfWeek,
        page:0, 
        page_size: 10
    }
    
    return HTTPService.sendPostReqeust(ApiDomain + '/result_paging', undefined, params)
}

Api.requestMeta = function (endpoint = '') {
    return HTTPService.sendPostReqeust(ApiDomain + '/metadata', undefined, {endpoint})
}

export default Api;