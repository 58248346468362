import React from "react";
import { useTranslation } from "react-i18next";

import Image from 'react-image-webp';
import { StyledContainerFooter } from "config/styles";
import { useStyles } from "./styles";

import bfooterPng1 from 'assets/img/footer/B1.png';
import bfooterPng1Wepb from 'assets/imgWebp/B1.webp';
import bfooterPng2 from 'assets/img/footer/B2.png';
import bfooterPng2Wepb from 'assets/imgWebp/B2.webp';
import bfooterPng3 from 'assets/img/footer/B3.png';
import bfooterPng3Wepb from 'assets/imgWebp/B3.webp';
import bfooterPng4 from 'assets/img/footer/B4.png';
import bfooterPng4Wepb from 'assets/imgWebp/B4.webp';
import bfooterPng5 from 'assets/img/footer/B5.png';
import bfooterPng5Wepb from 'assets/imgWebp/B5.webp';

const footerDataPayment = [
	{
		id: 1,
		name: 'company1',
		url: bfooterPng1,
		webUrl: bfooterPng1Wepb
	}, {
		id: 2,
		name: 'company2',
		url: bfooterPng2,
		webUrl: bfooterPng2Wepb
	} , {
		id: 3,
		name: 'company2',
		url: bfooterPng3,
		webUrl: bfooterPng3Wepb
	}  , {
		id: 4,
		name: 'company2',
		url: bfooterPng4,
		webUrl: bfooterPng4Wepb
	}  , {
		id: 5,
		name: 'company2',
		url: bfooterPng5,
		webUrl: bfooterPng5Wepb
	}
]

const Footer = () => {
	const { t } = useTranslation()
	const classes = useStyles();
	const handleClickImage = (e)=> {
		console.log('click image');
	}
	const companyName = t('CompanyName')
	let footerContent = t('FooterCopyright');
	footerContent = footerContent.replace('[company_name]',companyName)
	
	return <div className={classes.footer}>
		<div className={classes.fotter_containner_top}>
			<StyledContainerFooter>
				<div className={classes.footerClass}>
					<div className={classes.footerRight}>
						<pre className={classes.footerDeposit}>{t('FooterText')}</pre>
					</div>
					<div className={classes.footerLeft}>
						<div className={classes.footerTextLeft}>
							{
								footerDataPayment.map((itemIcon, index)=><Image onClick={handleClickImage} key={index} alt='compay partnner' src={itemIcon.url} webp={itemIcon.webUrl} className={classes.imageItemFlag} />)
							}
						</div>
					</div>
				</div>
			</StyledContainerFooter>
		</div>
		<div className={classes.fotter_containner_bottom}>
				<div className={classes.footerClass}>
					<div className={classes.footerBottomContent}>
						{footerContent}
					</div>
				</div>
		</div>
	</div>
};

export default Footer
