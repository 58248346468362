import React from "react";
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import Image from 'react-image-webp';

import clamp from 'assets/img/common/about_us.png';
import clampWepb from 'assets/imgWebp/about_us.webp';

const AboutUs = () => {
  const classes = useStyles()
  const {t} = useTranslation();
  
  return (
    <div className={classes.container_page} style={{animation: 'fadeIn ease-in .1s'}}>
      <div className={classes.about_title}>
        <Image alt='bet winner' src={clamp} webp={clampWepb} className={classes.imageItem} />
      </div>
      <div className={classes.content}>
        <span>
          <div className={classes.content_group}>
            <pre className={classes.content_center}>
              {t('AboutUsContent')}
            </pre>
          </div>
        </span>
      </div>
      <br />
    </div>
  )
};

export default AboutUs
