import { makeStyles } from "@material-ui/core/styles";
import {font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
	footer: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
		flexDirection: 'column',
		// height: layout.footerHeight,
		height: '100%',
		backgroundColor: '#dcdcda',
		[theme.breakpoints.down('sm')]: {
			// height: '80px',
			// padding: '1vw 0',
		},
		[theme.breakpoints.down('xs')]: {
			// height: '60px',
			// padding: '1vw 0',
		}
	},
	footerClass: {
		display: 'flex',
		width: "100%",
		height: "100%",
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		textAlign: 'center',
	},

	footerLeft: {
		width: "100%",
		display: 'flex',
		alignItems: "center",
		justifyContent: 'center'
	},
	footerTextLeft: {
		display: 'flex',
		alignItems: "center",
		justifyContent: 'center',
		flexDirection: 'row',
		flexWrap: 'wrap',
		fontSize: font.smallSize,
		margin: '0px 20px 0 20px',
		[theme.breakpoints.down('sm')]: {
			fontSize: font.smallSize__mobile,
		}
	},

	footerRight: {
		width: "100%",
		display: 'flex',
		alignItems: "center",
		justifyContent: 'center',
		fontSize: font.smallSize,
		color: 'black',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2vw',
			alignItems: "center",
		},
		'& pre': {
			fontSize: font.nomalSize,
			[theme.breakpoints.down('sm')]: {
				fontSize: '2vw',
			},
		}
	},
	fotter_containner_bottom: {
		display: 'flex',
		height: '20px',
		width: '100%',
		backgroundColor: '#cacdc6',
		fontSize: font.smallSize,
		// height: '30%'
		[theme.breakpoints.down('sm')]: {
			fontSize: '2vw',
			alignItems: "center",
		},
		'& pre': {
			fontSize: font.nomalSize,
			[theme.breakpoints.down('sm')]: {
				fontSize: '2vw',
			},
		}
	},

	fotter_containner_top: {
		display: 'flex',
		width: '100%',
		height: '100%'
	},
	footerBottomContent: {
		width: "100%",
		height: '30%',
		display: 'flex',
		alignItems: "center",
		justifyContent: 'center',
		backgroundColor: '#cacdc6',
		fontSize: font.smallSize,
		color: 'black',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6vw',
			alignItems: "center",
		},
		'& pre': {
			fontSize: font.nomalSize,
			[theme.breakpoints.down('sm')]: {
				fontSize: '1.6vw',
			},
		}
	},

	footerDeposit: {
		marginTop: '10px'
	},
	
	imageItem: {
		height: '60px',
		margin: '5px',
		[theme.breakpoints.down('sm')]: {
			height: '55px',
			margin: '2px',
		},
		[theme.breakpoints.down('xs')]: {
			height: '8.5vw',
			margin: '1px',
		}
	},
	imageItemFlag: {
		height: '20px',
		margin: '5px',
		[theme.breakpoints.down('sm')]: {
			height: '20px',
		},
		[theme.breakpoints.down('xs')]: {
			height: '4vw',
		}
	}

}));
