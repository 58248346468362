import React, {useState} from 'react';
import { map } from 'lodash';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const width5Columns = ['20%','20%', '40%','20%']
const RTable = ({ data = {}, style, onClick, onMouseMove, onMouseLeave, selectedIndex = -1 }) => {
	const classes = useStyles();
	const [howerIndex, setHowerIndex] = useState(-1)
	const theme = useTheme();
  	const matches = useMediaQuery(theme.breakpoints.down('xs'));
	const handleClick = (index)=> {
		if (onClick) {
			if (selectedIndex === index) {
				onClick(-1)
				setHowerIndex(-1)
			} else {
				onClick(index)
			}
		}
	}

	const handleMoveOver = (index)=> {
		setHowerIndex(index)
		if (onMouseMove) {
			onMouseMove(index)
		}
	}

	const handleMoveLeave = (index)=> {
		setHowerIndex(-1)
		if (onMouseLeave) {
			onMouseLeave(index)
		}
	}
	
	const renderHeader = () => {
		const { header } = data;
		return <div className={classes.table__header_1} >
			{
				map(header, (head, i) => {
					const style = {}
					if (header.length === 4) {
						style.width =  width5Columns[i]
						if (i !== 2) {
							style.justifyContent = 'left';
						}
						if (i === 3) {
							style.justifyContent = 'center'
						} else if (i === 1) {
							style.justifyContent = 'left'
						}
					}
					return <div className={classes.row_header_table} style={style} key={i}>{head}</div>
				})
			}
		</div>
	};

	const renderBody = () => {
		const { body } = data;
		return map(body, (row, i) => {
			let classNameRow = classes.body__background
			if (selectedIndex === -1 && howerIndex === i) {
				classNameRow = classes.body__background_2
			}

			if (selectedIndex === i) {
				classNameRow = classes.body__background_2
			}

			return <div key={i} className={classes.body_bg_1} onClick={()=> handleClick(i)} onMouseMove={()=> handleMoveOver(i)} onMouseLeave={()=> handleMoveLeave(i)}>
				<div className={classNameRow} />
				{
					map(row, (data, j) => {
						let style = {};
						if (row.length === 4) {
							style.width =  width5Columns[j]
							if (j !== 2) {
								style.justifyContent = 'flex-start';
							}
							if (j === 3) {
								style.justifyContent = 'center'
							} else if (j === 1) {
								style.justifyContent = 'flex-start'
							}
						}

						if (j === 0) {
							if (matches) {
								style.paddingLeft = '3px'
							} else {
								style.paddingLeft = '10px'
							}
						}
						if (Array.isArray(data)) {
							return <div style={style} key={j} className={classes.row_table}>
								{map(data, (item, index) => <div className={classes.row_circle} key={index}>{item}</div>)}
							</div>
						} else if (typeof data === "object" && data.name === 'day') {
							return <div style={style} className={classes.row_table_break} key={j}>
								<div className={classes.row_table_sub_top} >{data.dayOfWeek}</div>
								<div className={classes.row_table_sub_bottom} >{data.date}</div>
							</div>
						}
						return <div style={style} className={classes.row_table} key={j}>{data}</div>
					}
					)
				}
			</div>
		})
	};

	return <div className={classes.table} style={style}>
		<div className={classes.table__header}>
			{renderHeader()}
		</div>
		<div className={classes.table__body_1}>
			{renderBody()}
		</div>
	</div>
};

export default RTable;
