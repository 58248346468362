import React from 'react';
import Image from 'react-image-webp';
import Logo from 'assets/img/home/logo.png';
import LogoWepb from 'assets/imgWebp/logo.webp';
import { useStyles } from './styles'

const LogoCompany = () => {
  const classes = useStyles();
  
  return (
    <>
      <div className={classes.containner}>
        <Image alt='logo' src={Logo} webp={LogoWepb} className={classes.img_logo} />
      </div>
    </>
  )
};

export default LogoCompany
