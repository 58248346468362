const india = {
  translations: {
    "AboutUs": "About Us",
    "Date": "Date",
    "FooterCopyright": "COPYRIGHT © 2005 [company_name]. ALL RIGHTS RESERVED.",
    "headerHome": "Home",
    "Results": 'Results',
    'DrawNo': 'Draw No.',
    'AboutUsContent': `Did you know you can play the top world lotteries online from India? It's easy to buy tickets for top international lotteries like Mega Millions, Powerball and EuroMillions from your computer or phone without having to visit America or Europe.

These international lotteries are not subject to Indian gambling restrictions so you can play from anywhere in the country and they offer much higher jackpots than the Indian lotteries, often with improved overall chances of winning any prize.

Indialottery.net is brought to you by a small, dedicated team of professional writers, lottery experts and local Indian talent. Having all spent time living in India, The Team recognised the chaos that surrounds news, false news, rumour and hearsay. This is no more evident than in the haphazard sector of lotteries, both online and in the street.

Indian Lotteries seem to come and go on a daily basis, winning numbers aren't announced until a week or so after the drawing and no reliable source existed for the dissemination of reliable lottery results. Scams are rife.

And so indialottery.net was born with the mission to "Bring reliability to the World of Indian Lotto" - a website that people could trust to report the correct winning numbers and results in a timely manner.`,
    'Sun': 'Sunday',
    'Mon': 'Monday',
    'Tue': 'Tuesday',
    'Wed': 'Wednesday',
    'Thu': 'Thursday',
    'Fri': 'Friday',
    'Sat': 'Saturday',
    'No': 'No',
    'Day': 'Day', 
    'Result': 'Result', 
    'Prize': 'Prize',
    'FooterText': 'Deposit Funds Into Your Player Account Via The Following Methods :',
    'CompanyName': 'INDIALOTTERY',
    'LastestResult': 'PASS RESULT',
    'FooterText1': 'All times displayed in China Standard Time (CST is UTC+8) unless stated otherwise\nPlease note: Pricing on shanghaipools.net differs from that found at traditional retail lottery agencies.',
    'ResponseTitle1':	'What you should know',
    'ResponseContent1':	'Gambling is a leisure activity. Whether you or someone you know chooses to gamble, it’s important to understand that gambling comes with potentially serious risks. Because the fact is most people lose when they play the lottery, play a casino game, or place a bet. That’s why you, your family, and your friends should know how to protect yourselves when it comes to problem gambling.',
    'ResponseTitle2':	'Problem Gambling',
    'ResponseContent2':	'Almost everyone who develops a gambling problem thinks it’s just a matter of time until they’ll hit the jackpot. They often gamble to the point that their relationships, finances, and health are negatively affected. That’s why it’s important to make gambling safer.',
    'ResponseTitle3':	'Don’t think of gambling as a way to make money.',
    'ResponseContent3':	`The bottom line is that gambling establishments like land-based casinos and online gambling sites are set up to take in more money than they pay out. This means that over time, you will lose more money than you win.
And, remember it's not just casinos. All forms of gambling have the same principle – the vast majority of people lose so that a very small minority can have big wins. Virtually all people with gambling problems hold the false expectation that they are the ones who will be the big winners.
That belief feeds the problem.`,
    'ResponseTitle4':	'Always gamble with money that you can afford to lose.',
    'ResponseContent4':	'Gamble with money that you set aside for fun, like going to the movies or going out for drinks. Never use money that you need for important things like rent, bills, tuition, etc.',
    'ResponseTitle5':	'Set a money limit.',
    'ResponseContent5':	'Decide how much money you can afford to lose before you play. When you have lost that amount of money, quit. If you win – enjoy, but remember it won’t happen most of the time.',
    'ResponseTitle6':	'Set a time limit.',
    'ResponseContent6':	'Decide how much time you can afford to spend gambling. When you reach that time limit, stop gambling.',
    'ResponsiblePlay': 'Responsible Play'
 }
};

export default india;
