import { makeStyles } from "@material-ui/core/styles";

import {font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  winners: {
    display: 'flex',
    position: 'absolute',
    right: '10px',
    bottom: '40%',
    width: 'auto',
    fontSize: '30px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection:'column',
    minWidth: '120px',
    maxWidth: '200px',
    backgroundColor: '#c1b8ae',
    borderRadius: '5px',
    zIndex: '2',
    transform: "translate(0, 50%)",
    [theme.breakpoints.down('sm')]: {
      minWidth: '50px',
      // maxWidth: '150px',
      border: `1px solid #fff`,
      backgroundColor: '#fff',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '90px',
      maxWidth: '150px',
      backgroundColor: '#fff',
      border: `1px solid #fff`,
    }
  },


  winners__table__wrapper: props => ( {
    display: 'flex',
    width: '100%',
    padding: '10px',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
      padding: '5px',
    },
  }),

  winners__table: {
    display: 'inline-block',
    width: '100%',
    margin: '1px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  winner_title: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '10px',
    backgroundImage: 'linear-gradient(to bottom, #f59b07 , #ec7a00)',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      padding: '5px',
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      fontFamily: font.boldFamily,
      margin: '0px',
      color: 'white',
      fontSize: font.nomalSize,
      [theme.breakpoints.down('sm')]: {
        // fontSize: font.nomalSize__mobile,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: font.nomalSize__mobile,
      }
    }
  },

  rowSingle: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    '& pre': {
      display: 'flex',
      flex: '0.5',
      alignItems: 'center',
      justifyContent: 'flex-start',
      margin: '0px',
      color: 'black',
      // fontFamily: font.boldFamily,
      fontSize: font.nomalSize,
      [theme.breakpoints.down('sm')]: {
        // fontSize: font.nomalSize__mobile,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: font.nomalSize__mobile,
      }
    },
    '& :nth-child(2)': {
      justifyContent: 'flex-end',
    },
  },
  rowMulti: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    '& span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      // fontFamily: font.boldFamily,
      margin: '0px',
      color: 'black',
      fontSize: font.nomalSize,
      [theme.breakpoints.down('sm')]: {
        // fontSize: font.nomalSize__mobile,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: font.nomalSize__mobile,
      }
    }
  },
  rowMultiItenm: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    '& span': {
      display: 'flex',
      width: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      // fontFamily: font.boldFamily,
      margin: '0px',
      color: 'black',
      fontSize: font.nomalSize,
      [theme.breakpoints.down('sm')]: {
        fontSize: font.nomalSize__mobile,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: font.nomalSize__mobile,
      }
    }
  },

  image_title: {
    height: '12px',
    width: 'auto',
    margin:'2px 2px 0px 2px',
    [theme.breakpoints.down('sm')]: {
      margin:'2px 2px 0px 2px',
    }
  },
  bold__content: {
    fontFamily: font.boldFamily,
    // marginRight: '10px'
    paddingRight: '10px'
  }

}));
