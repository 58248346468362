import { makeStyles } from "@material-ui/core/styles";

import { color, font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  menu__list: {
    display: 'flex',
    padding: '20px 0 10px',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 0 0px',
    }
  },
  menu__routers: {
    display: 'flex',
    padding: '20px 0 10px',
    alignItems: 'center',
    justifyContent:'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5vw',
      width: '100%',
      height: '40px',
      padding: '0px 0px 0px 0px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.5vw',
      width: '100%',
      height: '30px',
      padding: '0px 0px 0px 0px',
    }
  },
  menu__item_language: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginRight: '45px',
    color: color.black,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginRight: '0px',
      width: '34%',
      fontSize: '2.0vw',
      height: '100%',
      color: 'black',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginRight: '0px',
      width: '34%',
      fontSize: '2.5vw',
      height: '100%',
      color: 'black',
    }
  },

  menu__item: {
    marginRight: '45px',
    color: color.black,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '0px',
      width: '34%',
      fontSize: '2.0vw',
      height: '100%',
      color: 'black',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '0px',
      width: '34%',
      fontSize: '2.5vw',
      height: '100%',
      color: 'black',
    }
  },

  menu__item_selected: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    color: color.black,
    fontFamily: font.boldFamily,
    '& .after': {
      display: 'none',
      border: `1px solid #ff9933`,
      borderRadius: '5px',
      backgroundColor: '#ff9933',
      width: '23px',
      height: '3px',
      marginTop: '2px',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'flex-start',
      color: '#fff',
      marginRight: '0px',
      // width: '100%',
      height: '100%',
      '& .after': {
        display: 'flex',
        position: 'absolute',
        top: '70%',
        border: `1px solid #ff9933`,
        backgroundColor: '#ff9933',
      }
    }
  },

  menu__item_link: {
    position: 'relative',
    display: 'flex',
    color: color.black,
    fontSize: '18px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      color: 'black',
      fontSize: '18px',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '0px',
      // width: '100%',
      height: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      color: 'black',
      fontSize: '12px',
      justifyContent: 'center',
      // alignItems: 'center',
      marginRight: '0px',
      // width: '100%',
      height: '100%',
    },
  }
}));
