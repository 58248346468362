import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useStyles } from './styles';

import './styles.css'
import { map } from 'lodash';

const TableWinners = ({ data, index }) => {
  const props = {index}
  const classes = useStyles(props);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const mapDataToTable = (data = [], header = []) => {
    if (data.length > 0) {
      let colum = 3;
      if (matches) {
        colum = Math.round(data.length / 2);
      }
      const body = [];
      for (let i = 0; i < 10 ; i+=colum) {
        const b = data.slice(i,i + colum)
        if (b.length > 0) {
          body.push(b)
        } else {
          break
        }
      }
      return  {
        header,
        body
      };
    }

    return  {
      header,
      body: data
    };
  };

  const renderTableDesktop = () => {
    const items = data.resuls;
    const table1 = mapDataToTable(items)
    return (
      <div className={classes.winners__table__wrapper}>
        <div className={classes.winners__table}>
          {/* <Table data={table1} /> */}
          {map(table1.body, (itemArr, index)=> {
            return <div key={index} className={classes.group__column}>
              {map(itemArr, (numbers,indexNumber)=> {
                return <div key={indexNumber} className={classes.table_container_numers}> {map(numbers, (number, z)=><span className={classes.table_number} key={z}>{number}</span>)}</div>
              })}
            </div>
          })}
        </div>
      </div>
    )
  };

  const renderTitle = () => {
    return (
      <div className={classes.winner_title}>
          <span>
            {data.name}
          </span>
      </div>
    )
  }

  return <div className={classes.winners}>
    {renderTitle()}
    {renderTableDesktop()}
  </div>
};

export default TableWinners;
