import { makeStyles } from "@material-ui/core/styles";

import { font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  container_page: {
    margin: '0 10px 0 10px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 10px 0 10px',
    },
    [theme.breakpoints.down('xs')]: {
			margin: '0 10px 0 10px',
		}
  },
  howtoplay_title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textTransform: 'uppercase',
    '& span': {
      color: 'black',
      padding:'5px',
      paddingLeft:'0px',
      fontSize: '25px',
      fontFamily: font.boldFamily,
      [theme.breakpoints.down('sm')]: {
        fontSize: '3.0vw',
      }
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 10px 0 10px',
    }
  },

  lable_image: {
    height: '12px',
    width: 'auto',
    margin:'2px 2px 0px 0px'
  },

  content: {
    display: 'flex',
    margin: '1px',
    color: 'black',
    fontSize: font.nomalSize,
    '& span': {
      margin: '1px',
      textAlign: 'left',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
      margin: '0 10px 0 10px',
    }
  },
  content_center: {
    display: 'flex',
    justifyContent:'flex-start',
    alignItems:'flex-start',
    fontSize: font.nomalSize,
    margin: '5px 0px 0 0px',
    whiteSpace: 'pre-wrap',
    color: 'black',
    textAlign: 'justify',
    textJustify: 'inter-word',
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
      margin: '3px 0px 0 0px',
    }
  },
  content__image: {
    display: 'flex',
    justifyContent:'center',
    alignItems:'flex-start',
    margin: '1px',
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
      margin: '0 10px 0 10px',
    }
  },
  imageItemFlag: {
    height: '200px',
		margin: '5px',
		[theme.breakpoints.down('sm')]: {
			height: '150px',
		},
		[theme.breakpoints.down('xs')]: {
			height: '20vw',
		}
  },
  content_title: {
    fontSize: font.nomalSize,
    fontFamily: font.boldFamily,
    margin: '20px 0 0 0',
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
      margin: '10px 0 10px 0',
    }
  },
  content_group: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
}));
