import React from 'react';
import TableNumber from 'components/Numbers';
import { useStyles } from './styles';
import Image from 'react-image-webp';
import { useTranslation } from 'react-i18next';

import Top1 from 'assets/img/home/champion_icon.png';
import Top1Wepb from 'assets/imgWebp/champion_icon.webp';

import './styles.css'

const TableTopThree = ({data, period = {date: '', code: ''}}) => {
  const classes = useStyles()
  const {t} = useTranslation()
  
  const renderTitle = () => {
    let TimePeriod = '------------';
    if (period && period.date) {
      TimePeriod = `${period.date} - ${period.code}`
    }

    return (
      <div className={classes.winner_title}>
          <div className={classes.contanner__image}>
            <Image className={classes.image_title} alt='button prev' src={Top1} webp={Top1Wepb} />
          </div>
          <div className={classes.title_group}>
            <span>
              {t('LastestResult')}
            </span>
            <pre className={classes.underText}>
              {TimePeriod}
            </pre>
          </div>
          
      </div>
    )
  }

  return <div className={classes.winners}>
      {renderTitle()}
      <div className={classes.winners__table__wrapper}>
        {
          data.map((item, index)=> {
            return <div key={index} className={classes.winners__table}>
                <TableNumber data={item} index={index} />
            </div>
          })
        }
      </div>
  </div>
};

export default TableTopThree;
