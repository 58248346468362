import { makeStyles } from "@material-ui/core/styles";

import {font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  winners: {
    width: '100%',
    // padding: '5px',
    marginBottom: '15px',
    borderRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '10px',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '5px',
    }
  },


  winners__table__wrapper: props => ( {
    display: 'flex',
    width: '100%',
    backgroundColor: '#c1b8ae',
    padding: '10px 0 10px 0',
    borderRadius: '5px',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px',
      fontSize: font.nomalSize__mobile,
    },
  }),

  winners__table: {
    display: 'flex',
    width: '100%',
    margin: '1px',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center', 
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexWrap: 'wrap',
    },
  },

  winner_title: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: '10px',
    '& span': {
      fontFamily: 'CenturyGothicBold',
      margin: '0px',
      color: '#3b3939',
      paddingBottom: '5px',
      fontSize: font.bigSize,
      [theme.breakpoints.down('sm')]: {
        fontSize: font.bigSize__mobile,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '4.2vw',
        paddingBottom: '3px',
      }
    }
  },

  image_title: {
    height: '12px',
    width: 'auto',
    margin:'2px 2px 0px 2px',
    [theme.breakpoints.down('sm')]: {
      margin:'2px 2px 0px 2px',
    }
  },
  table_container_numers: {
    display: 'flex',
    alignItems:'center',
    justifyContent: 'center',
    flexDirection: 'colum',
    marginTop:'5px',
    marginBottom:'5px',
  },
  table_number: {
    display: 'flex',
    alignItems:'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: '38px',
    height: '38px',
    fontSize: '28px',
    padding: '1px',
    margin: '5px',
    // fontFamily: font.boldFamily,
    // boxSizing: 'content-box',
    color: '#000',
    backgroundColor: '#fff',
    [theme.breakpoints.down('sm')]: {
      // borderRadius: '4px',
      fontSize: '24px',
      width: '30px',
      height: '30px',
      margin: '2px',
    },
    [theme.breakpoints.down('xs')]: {
      // borderRadius: '2px',
      fontSize: '4.5vw',
      width: '6vw',
      height: '6vw',
      margin: '1px',
    }
  }
}));
