import { makeStyles } from "@material-ui/core/styles";
import {layout } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  containner: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    width: '100%',
    minHeight: layout.headerHeighBg,
    marginBottom: '5px',
    [theme.breakpoints.down('sm')]: {
      minHeight: layout.headerHeighBg__mobile,
      '& ball__left': {
        display: 'flex'
      }
    },
  },
  
  img_logo: {
    position: 'absolute',
    left: '0',
    bottom: '0',
    width: '25%',
    [theme.breakpoints.down('sm')]: {
      transform: 'translateX(-50%)',
      width: '40%',
      left: '50%',
      bottom: '1%',
    },
    [theme.breakpoints.down('xs')]: {
      transform: 'translateX(-50%)',
      width: '45%',
      left: '50%',
      bottom: '1%',
    },
  },
}));
