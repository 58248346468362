import React, { useContext, useEffect, useState } from 'react';
import { useStyles } from './AppStyles';
import Routes from './routers'
import { AuthContext } from 'context/AuthContext'
import { get } from 'lodash';

function App() {
	const classes = useStyles();
	const Auth = useContext(AuthContext);
	const [resultHomeData, setResultHomeData]  = useState(Auth.resultHomeData)

	useEffect(() => {
		Auth.requestAuth().then((res) => {
			const isSuccess = get(res, 'data.success', false);
			if (isSuccess) {
				const data = get(res, 'data.data', {});
				Auth.dataList = data;
				const hData = Auth.mapDataHomePage(data)
				setResultHomeData(hData)
			}
		});
	}, [Auth]);
	
	return (
		<AuthContext.Provider value={{ ...Auth, resultHomeData}}>
			<div className={classes.App}>
				<Routes />
			</div>
		</AuthContext.Provider>
	);
}

export default App;
