import React from "react";
import Image from 'react-image-webp';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

import clamp from 'assets/img/common/res.png';
import clampWepb from 'assets/imgWebp/res.webp';

const HowToPlay = () => {
  const classes = useStyles()
  const {t} = useTranslation();
  
  return (
    <div className={classes.container_page} style={{animation: 'fadeIn ease-in .1s'}}>
      <div className={classes.howtoplay_title}>
          <span>
          {t('ResponsiblePlay')}</span>
      </div>
      <div className={classes.content}>
        <span>
          <pre className={classes.content_title}>
            {t('ResponseTitle1')}
          </pre>
          <pre className={classes.content_center}>
            {t('ResponseContent1')}
          </pre>    
        </span>
      </div>
      <div className={classes.content}>
        <span>
          <pre className={classes.content_title}>
            {t('ResponseTitle2')}
          </pre>
          <div className={classes.content_group}>
            <pre className={classes.content_center}>
              {t('ResponseContent2')}
            </pre>
            <div className={classes.content__image}>
              <Image alt='bet winner' src={clamp} webp={clampWepb} className={classes.imageItemFlag} />
            </div>
          </div>
        </span>
      </div>
      <div className={classes.content}>
        <span>
          <pre className={classes.content_title}>
            {t('ResponseTitle3')}
          </pre>
          <pre className={classes.content_center}>
            {t('ResponseContent3')}
          </pre>    
        </span>
      </div>
      <div className={classes.content}>
        <span>
          <pre className={classes.content_title}>
            {t('ResponseTitle4')}
          </pre>
          <pre className={classes.content_center}>
            {t('ResponseContent4')}
          </pre>    
        </span>
      </div>
      <div className={classes.content}>
        <span>
          <pre className={classes.content_title}>
            {t('ResponseTitle5')}
          </pre>
          <pre className={classes.content_center}>
            {t('ResponseContent5')}
          </pre>    
        </span>
      </div>
      <div className={classes.content}>
        <span>
          <pre className={classes.content_title}>
            {t('ResponseTitle6')}
          </pre>
          <pre className={classes.content_center}>
            {t('ResponseContent6')}
          </pre>    
        </span>
      </div>
      <br />
    </div>
  )
};

export default HowToPlay
