import { makeStyles } from "@material-ui/core/styles";
import { font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  winners: {
    position: 'relative',
    display: 'flex',
    flex: '100%',
    justifyContent:'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '15px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '10px',
      maxWidth: '1560px',
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '1560px',
      width: '100%',
      marginBottom: '5px',
    }
  },

  winner_title: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      width: '100%'
    },
    '& span': {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexDirection: 'column',
      textDecoration: 'underline',
      margin: '0px',
      color: '#3d3939',
      fontSize: '36px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '33px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '28px',
      },
    }
  },
  title_group: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent:'center',
    alignItems: 'center'
  },  

  underText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0px',
    color: '#ea9e00',
    width: '100%',
    // marginTop: '5px',
    marginBottom: '3px',
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
      // marginTop: '3px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '11px',
      // marginTop: '2px',
    }
  },

  winners__table__wrapper: {
    display: 'flex',
    width: '100%',
    borderRadius: '5px',
    backgroundColor: '#ff9933',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      borderRadius: '0px',
      fontSize: font.nomalSize__mobile,
    },
  },

  winners__table: {
    display: 'flex',
    justifyContent: 'center',
    flex: '1',
    // backgroundColor: '#2e041c',
    
  },
  winner1stCol: {
    paddingLeft: '20px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '6.25vw',
    },
  },

  contanner__image: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    width: '70px',
    [theme.breakpoints.down('sm')]: {
      width: '70px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '60px',
    },
  },

  image_title: {
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    height: '70px',
    width: 'auto',
    margin:'2px 2px 0px 2px',
    [theme.breakpoints.down('sm')]: {
      margin:'2px 2px 0px 2px',
      height: '70px',
    },
    [theme.breakpoints.down('xs')]: {
      margin:'2px 2px 0px 2px',
      height: '60px',
    }
  },
}));
