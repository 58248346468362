import { makeStyles } from "@material-ui/core/styles";

import { color, font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  container_page: {
    [theme.breakpoints.down('sm')]: {
      backgroundColor: color.mainBackground
    }
  },
  about_title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    '& span': {
      padding:'5px',
      fontSize: '40px',
      fontFamily: font.boldFamily,
      [theme.breakpoints.down('sm')]: {
        fontSize: '4.5vw',
      }
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 20px 0 20px',
    }

  },

  lable_image: {
    height: '12px',
    width: 'auto',
    margin:'2px 2px 0px 0px'
  },

  content: {
    display: 'flex',
    margin: '1px',
    color: 'black',
    fontSize: '40px',
    '& span': {
      fontSize: '40px',
      color: 'black',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        fontSize: '4.5vw',
      }
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '4.5vw',
      margin: '0 10px 0 10px',
    }
  },
  content_title: {
    fontSize: font.nomalSize,
    fontFamily: font.boldFamily,
    margin: '20px 0 0 0',
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
      margin: '10px 0 10px 0',
    }
  },
  content_center: {
    fontSize: font.nomalSize,
    whiteSpace: 'pre-wrap',
    
    textAlign: 'justify',
    textJustify: 'inter-word',

    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
      // margin: '0 20px 0 20px',
    }
  },
  content_group: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'row'
  },
  imageItem: {
    height: '300px',
		margin: '5px',
		[theme.breakpoints.down('sm')]: {
			height: '250px',
		},
		[theme.breakpoints.down('xs')]: {
			height: '25vw',
		}
  }
}));
