import { makeStyles } from "@material-ui/core/styles";
import {font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  numbers: {
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    flexDirection: 'column',
    // backgroundColor: '#2e041c',
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
    },
  },

  number_title: {
    display:'flex',
    justifyContent:'flex-start',
    alignItems:'flex-end',
    height: '50px',
    width:'100%',
    [theme.breakpoints.down('sm')]: {
      height: '40px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '25px',
    },
  },

  header_title: {
    '& span': {
      fontFamily: font.boldFamily,
      fontSize: font.largeSize,
      [theme.breakpoints.down('sm')]: {
        fontSize: font.largeSize__mobile
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: font.bigSize__mobile
      }
    }
  },
  imageTop: {
    height: '100%',
    width: 'auto'
  },

  number__body: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    justifyContent:'center',
    alignItems:'center',
    flexDirection: 'row',
    padding: '10px 0px 10px 0px',
    [theme.breakpoints.down('ms')]: {
      padding: '7px 0px 7px 0px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '5px 0px 5px 0px',
    }
  },

  number__body_background: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    height: '100%',
    left: '0',
    top: '0',
    opacity: '0.3',
    zIndex: '0',
  },  

  number_content: {
    // boxSizing: 'content-box',
    borderRadius: '50%',
    color: '#000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1px',
    margin: '3px',
    width: '40px',
    height: '40px',
    fontSize: '28px',
    backgroundColor: 'white',
    zIndex: '1',
    [theme.breakpoints.down('sm')]: {
      // borderRadius: '4px',
      fontSize: '24px',
      width: '30px',
      height: '30px',
      margin: '2px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '4.5vw',
      width: '6vw',
      height: '6vw',
      margin: '1px',
    }
    // [theme.breakpoints.down('sm')]: {
    //   borderRadius: '4px',
    //   fontSize: '20px',
    //   width: '28px',
    //   height: '35px',
    //   margin: '1px',
    // },
    // [theme.breakpoints.down('xs')]: {
    //   borderRadius: '2px',
    //   fontSize: '3vw',
    //   width: '3.8vw',
    //   height: '5vw',
    //   margin: '1px',
    // }
  }
}));
